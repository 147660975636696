<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <h1>DASH USER</h1>
  </v-container>
</template>

<script>

  export default {
    name: 'DashboardDashboard',

    data () {
      return {
        dialog: false,
      }
    },
  }
</script>
